// Spacing set by design.

const spacing = {
  none: '0',
  xxs: '.25rem', // 4px
  xs: '.5rem', // 8px
  sm: '0.75rem', // 12px
  md: '1.25rem', // 20px
  lg: '2rem', // 32px
  xl: '3.75rem', // 60px
  xxl: '5rem', // 80px
  xxxl: '10rem', // 160px
};

export default spacing;
