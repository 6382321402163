import '../styles/global.css';
import type { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import type { ReactElement, ReactNode } from "react";
import Modal from "react-modal";
import GlobalStyle from "styles/globalStyles";

Modal.setAppElement("#__next");

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, pageProps: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// <Add new locale here>
const nextJSLocaleToISOLocaleMap: { [key: string]: string } = {
  nz: "en-nz",
  gb: "en-gb",
  au: "en-au",
  us: "en-us",
  ca: "en-ca",
  za: "en-za",
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();

  const page = (
    <>
      <Head>
        {router.locales &&
          router.locales.map((locale: string) => {
            return (
              <link
                key={locale}
                rel="alternate"
                hrefLang={
                  locale === "default"
                    ? "en"
                    : `${nextJSLocaleToISOLocaleMap[locale]}`
                }
                href={`https://www.attheminute.com${
                  locale === "default" ? "" : `/${locale}`
                }${router.asPath === "/" ? "" : router.asPath}`}
              />
            );
          })}
      </Head>
      <GlobalStyle />
      <Component {...pageProps} />
    </>
  );

  return getLayout(page, pageProps);
}
