import { createGlobalStyle } from "styled-components";
import spacing from "styles/spacing";
import {
  sFont,
  fontFamilyRegular,
  h1Styles,
  h2Styles,
  h3Styles,
  h4Styles,
  h5Styles,
  h6Styles,
  pStyles,
} from "styles/typography";

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
  */

  html, body {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: ${fontFamilyRegular};
    font-weight: 400;
    vertical-align: baseline;
    scroll-padding-top: 60px;
  }

  section {
    margin: 0;
    padding: 0;
  }

  /* Added to Fix Footer to bottom of viewport */
  .siteRoot {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    flex: 1;
  }
  footer {
    align-self: flex-end;
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  * {
    box-sizing: border-box;
  }

  body {
    background: var(--color-background);
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 400;
    word-wrap: break-word;
    color: var(--color-text);
  }

  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  h4 {
    ${h4Styles}
  }

  h5 {
    ${h5Styles}
  }

  h6 {
    ${h6Styles}
  }

  p {
    ${pStyles}
  }

  input {
    font-size: ${sFont};
    line-height: 1.6;
    font-weight: 400;
    color: var(--color-text);
  }
  
  body.ReactModal__Body--open {
    overflow: hidden
  }

  pre {
    margin-top: 0;
    margin-bottom: 0.625rem;
    overflow: auto;
    white-space: pre;
  }

  pre,
  code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace;
  }

  code {
    padding: ${spacing.xxs} ${spacing.xs};
    margin: 0;
    font-size: 85%;
    background-color: var(--color-backgroundHover);
    border-radius: 6px;
  }

  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: var(--color-mainBrandColour);
  }

  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: var(--color-mainBrandColour);
  }
`;

export default GlobalStyle;
